import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledChooseUserModal = styled.div`
  display: flex;
  color: ${Colors.black};
  background-color: ${Colors.white};
  padding-top: ${Rem(40)};
  padding-bottom: ${Rem(50)};
  flex-direction: column;

  @media (${BP.tablet}) {
    padding-top: ${Rem(120)};
    padding-bottom: ${Rem(100)};
  }
`;

export const StyledChooseUserModalRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (${BP.tablet}) {
    flex-direction: row;
  }
`;

export const StyledChooseUserModalIcon = styled.img`
  margin-top: ${Rem(5)};
  margin-left: ${Rem(10)}; 
  width: ${Rem(50)}; 

  @media (${BP.ipad}) {
    position: absolute;
    top: ${Rem(100)}; 
  }
`;
